export const SMDutyItemTypes = {
  OTHERS: {
    NAME: "Others",
    VALUE: 0,
  },
  KEY: {
    NAME: "Key",
    VALUE: 1,
  },
  RADIO_SET: {
    NAME: "Radio Set",
    VALUE: 2,
  },
  HANDPHONE: {
    NAME: "Handphone",
    VALUE: 3,
  },
  BODY_WORN_CAMERA: {
    NAME: "Body Worn Camera",
    VALUE: 4,
  },
};

export const HandoverTypes = {
  FULL_HANDOVER: {
    VALUE: 1,
  },
  SOFT_HANDOVER: {
    VALUE: 2,
  },
  SOFT_TAKEOVER: {
    VALUE: 3
  }
}
